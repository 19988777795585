<template>
    <div class="pa-0 ma-0">
        <v-card flat style="background-color: var(--v-background-base) !important" class="pa-0 ma-0">
            <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55"
                class="pa-0 ma-0">
                <v-toolbar-title>
                    <b class="mr-3">Organisation Mapping</b>
                </v-toolbar-title>
            </v-toolbar>

            <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
            }">
                <v-card-text :style="{
                    height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                    'overflow-y': 'auto',
                }">
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" style="height: 5vh">
                            <v-row no-gutters>
                                <v-col cols="12" sm="2" md="2" lg="2" class="text-center">
                                    <v-row>
                                        <v-col cols="12" style="max-height: 10vh">
                                            <v-select label="Choose Dark Matter Column" :items="formattedPartyOptions"
                                                outlined dense @change="handlePartyOptionChange"
                                                v-model="selectedPartyOption">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-spacer></v-spacer>

                                <v-col cols="12" sm="2" md="2" lg="2" class="text-center">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn large color="primary" @click="getOrganisationDetails = true"
                                                :disabled="selectedUnmappedArr.length === 0">
                                                Search for Organisations
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" style="height: 60vh">
                            <v-card style="height: 82vh" outlined>
                                <v-card-title>
                                    <span class="headline">Unmapped ({{ this.totalUnmapped }})</span>
                                    <v-spacer></v-spacer>
                                    <v-tooltip top>
                                        <template #activator="{ on }">
                                            <span v-on="on">
                                                <!-- <v-checkbox v-model="selectAllUnmapped"
                                                    @click="selectPageUnmappedOptions"></v-checkbox> -->
                                                <v-checkbox v-model="selectAllUnmapped"
                                                    @click="dialogSAUnmapped(selectAllUnmapped)"></v-checkbox>
                                            </span>
                                        </template>
                                        <span>Select All</span>
                                    </v-tooltip>
                                    <v-btn color="green" @click="resumeMapping" icon>

                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on">start</v-icon>
                                            </template>
                                            <span>Resume Mapping</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-card-title>
                                <el-input v-model="unmappedSearch" placeholder="Search" outlined dense
                                    :disabled="unmappedOptionsLoading && mappedOptionsLoading">
                                    <template slot="append">
                                        <span>
                                            <v-btn @click="dialogFilter = true" icon>
                                                <v-icon>
                                                    filter_alt
                                                </v-icon>
                                            </v-btn>
                                        </span>
                                    </template>
                                </el-input>
                                <div style="display: flex; justify-content: center;">
                                    <el-pagination style="color: var(--v-primaryText-base)"
                                        :current-page.sync="pageUnmapped" :pager-count="5"
                                        :page-size.sync="paramsUnmapped.limit" :page-sizes="[5, 10, 15, 25]"
                                        :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper' : 'sizes, prev, pager, next'"
                                        :total="totalUnmapped"></el-pagination>
                                </div>
                                <v-card-text style="max-height: 66.5vh; overflow-y: auto;">
                                    <v-progress-linear v-if="unmappedOptionsLoading" indeterminate
                                        color="primary"></v-progress-linear>
                                    <v-data-table v-if="showCBR === true" :key="updateKey" :headers="showCBRHeaders"
                                        :items="filteredUnmappedOptions" item-key="name" dense hide-default-footer 
                                        :options="{
                                            page: pageUnmapped,
                                            itemsPerPage: paramsUnmapped.limit,
                                            pageStart: 1,
                                         }">
                                        <template #item="{ item }">
                                            <tr :key="item.name">
                                                <td>
                                                    <v-checkbox dense @click="saveToSelectedUnmapped(item)"
                                                        v-model="item.selected"></v-checkbox>
                                                </td>
                                                <td style="width: 200px;">{{ item.name }}</td>
                                                <td class="text-right">{{ item.ids }}</td>
                                                <td class="text-right">{{ item.cbrIds }}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>

                                    <!-- <v-list v-if="showCBR === true" :key="updateKey" ref="unmappedList">
                                        <v-list-item>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on">
                                                        <v-checkbox v-model="selectAllUnmapped"
                                                            @click="selectPageUnmappedOptions"></v-checkbox>
                                                    </span>
                                                </template>
                                                <span>Select All</span>
                                            </v-tooltip>
                                            <v-list-item-content class="text-left" style="width: 200px;">
                                                <v-list-item-title><b>Name</b></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-subtitle><b>LogLive ID(s)</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-subtitle><b>CBR ID(s)</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-for="option in filteredUnmappedOptions" :key="option.name">
                                            <v-checkbox @click="saveToSelectedUnmapped(option)"
                                                v-model="option.selected"></v-checkbox>
                                            <v-list-item-content class="text-left" style="width: 200px;">
                                                <v-list-item-title style="white-space: pre-wrap;">{{ option.name
                                                }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-subtitle>{{ option.ids }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-subtitle>{{ option.cbrIds }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list> -->

                                    <v-data-table v-if="showCBR === false" :key="updateKey" :headers="showAddressHeaders"
                                        :items="filteredUnmappedOptions" item-key="name" dense hide-default-footer
                                        :options="{
                                            page: pageUnmapped,
                                            itemsPerPage: paramsUnmapped.limit,
                                            pageStart: 1,
                                         }">
                                        <template #item="{ item }">
                                            <tr :key="item.name">
                                                <td>
                                                    <v-checkbox dense @click="saveToSelectedUnmapped(item)"
                                                        v-model="item.selected"></v-checkbox>
                                                </td>
                                                <td style="width: 200px;">{{ item.name }}</td>
                                                <td>{{ item.consigneeAddress }}</td>
                                                <td class="text-right">{{ item.ids }}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>

                                    <!-- <v-list v-if="showCBR === false" ref="unmappedList">
                                        <v-list-item>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on">
                                                        <v-checkbox v-model="selectAllUnmapped"
                                                            @click="selectPageUnmappedOptions"></v-checkbox>
                                                    </span>
                                                </template>
                                                <span>Select All</span>
                                            </v-tooltip>
                                            <v-list-item-content class="text-left" style="width: 200px;">
                                                <v-list-item-title><b>Name</b></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle><b>Address</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-subtitle><b>LogLive
                                                        ID(s)</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-for="option in filteredUnmappedOptions" :key="option.name">
                                            <v-checkbox @click="saveToSelectedUnmapped(option)"
                                                v-model="option.selected"></v-checkbox>
                                            <v-list-item-content class="text-left" style="width: 200px;">
                                                <v-list-item-title style="white-space: pre-wrap;">{{ option.name
                                                }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle style="white-space: pre-wrap;">{{
                                                    option.consigneeAddress
                                                }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-subtitle>{{ option.ids }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list> -->
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" style="height: 60vh">
                            <v-card style="height: 82vh" outlined>
                                <v-card-title>
                                    <span class="headline">Mapped ({{ this.totalMapped }})</span>
                                    <v-spacer></v-spacer>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">
                                                <!-- <v-checkbox v-model="selectAllMapped"
                                                    @click="selectPageMappedOptions"></v-checkbox> -->
                                                <!-- <v-checkbox v-model="selectAllMapped"
                                                    @click="selectAllMappedDlg = true"></v-checkbox> -->
                                                <v-checkbox v-model="selectAllMapped"
                                                    @click="dialogSAMapped(selectAllMapped)"></v-checkbox>
                                            </span>
                                        </template>
                                        <span>Select All</span>
                                    </v-tooltip>
                                    <v-btn color="red" @click="dialogUnmap = true, commenceUnmapping()" icon>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" style="transform: rotateY(180deg);">start</v-icon>
                                            </template>
                                            <span>Commence Unmapping</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-card-title>
                                <el-input v-model="mappedSearch" placeholder="Search" outlined dense
                                    :disabled="unmappedOptionsLoading && mappedOptionsLoading">
                                </el-input>
                                <div style="display: flex; justify-content: center;">
                                    <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="pageMapped"
                                        :pager-count="5" :page-size.sync="paramsMapped.limit" :page-sizes="[5, 10, 15, 25]"
                                        :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper' : 'sizes, prev, pager, next'"
                                        :total="totalMapped"></el-pagination>
                                </div>
                                <v-card-text style="max-height: 66.5vh; overflow-y: auto;">
                                    <v-progress-linear v-if="mappedOptionsLoading" indeterminate
                                        color="primary"></v-progress-linear>
                                    <v-data-table :headers="mappedHeaders" :items="filteredMappedOptions" item-key="id"
                                        dense hide-default-footer
                                        :options="{
                                            page: pageMapped,
                                            itemsPerPage: paramsMapped.limit,
                                            pageStart: 1,
                                         }">
                                        <template #item="{ item }">
                                            <tr :key="item.id">
                                                <td>
                                                    <v-checkbox dense @click="saveToSelectedMapped(item)"
                                                        v-model="item.selected"></v-checkbox>
                                                </td>
                                                <td>{{ item.id }}</td>
                                                <td>{{ item.field }}</td>
                                                <td>{{ item.value }}</td>
                                                <td>{{ item.mappedField }}</td>
                                                <td>{{ item.mappedValue }}</td>
                                                <td>{{ item.mappedValueName }}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>

                                    <!-- <v-list ref="mappedList">
                                        <v-list-item>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on">
                                                        <v-checkbox v-model="selectAllMapped"
                                                            @click="selectPageMappedOptions"></v-checkbox>
                                                    </span>
                                                </template>
                                                <span>Select All</span>
                                            </v-tooltip>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title><b>ID</b></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle><b>Field</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle
                                                    style="white-space: pre-wrap;"><b>Value</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle><b>Mapped Field</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle style="white-space: pre-wrap;"><b>Mapped
                                                        Value</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle style="white-space: pre-wrap;"><b>Mapped
                                                        Name</b></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-for="option in filteredMappedOptions" :key="option.id">
                                            <v-checkbox @click="saveToSelectedMapped(option)"
                                                v-model="option.selected"></v-checkbox>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title>{{ option.id }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle>{{ option.field }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle style="white-space: pre-wrap;">{{ option.value
                                                }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle>{{ option.mappedField }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle style="white-space: pre-wrap;">{{ option.mappedValue
                                                }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-subtitle style="white-space: pre-wrap;">{{
                                                    option.mappedValueName
                                                }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list> -->
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card>

        <v-dialog v-model="getOrganisationDetails" persistent width="1200px" scrollable>
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Organisation Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon right @click="getOrganisationDetails = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <OrganisationMappingLookup :selection="selectedOrganisationOne" :item="item"
                        @refresh="getRelations(), clear()" @close="(relationshipDialog = false), (item = {})"
                        @selection="handleSelection" />

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMap" persistent width="1200px" scrollable>
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Mapping Confirmation</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon right @click="commenceMapping(), dialogMap = false">
                            <v-icon>check</v-icon>
                        </v-btn>
                        <v-btn icon right @click="dialogMap = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card>
                    <v-tabs v-model="tab" grow>
                        <v-tab>Searched Organisation</v-tab>
                        <v-tab>Selected Organisation(s)</v-tab>

                        <v-tab-item>
                            <!-- <v-card-text dark> -->
                            <v-data-table :headers="searchedOrgHeaders" :items="[selectedOrganisationOne]"
                                hide-default-footer>
                                <template #item="{ item }">
                                    <tr>
                                        <td v-for="header in searchedOrgHeaders" :key="header.value">
                                            <template v-if="header.value === 'countryRegion'">
                                                {{ item[header.value].name }}
                                            </template>
                                            <template v-else-if="header.value === 'organisationAddresses'">
                                                {{ getAddressString(item[header.value]) }}
                                            </template>
                                            <template v-else>
                                                {{ item[header.value] }}
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                            <!-- </v-card-text> -->
                        </v-tab-item>

                        <v-tab-item>
                            <v-data-table v-if="selectedUnmappedArr" :headers="unmappedSelectedHeaders"
                                :items="selectedUnmappedArr">
                            </v-data-table>

                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogFilter" width="400px">
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Advanced filter</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon right @click="filterStatus = null; filterId = null">
                            <v-icon>filter_alt_off</v-icon>
                        </v-btn>
                        <v-btn icon color="red" right @click="dialogFilter = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-list>
                        <v-list-group v-model="idGroup">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>IDs</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-switch color="primary" v-model="filterId" value="noID"
                                        label='No Organisation LogLive ID(s)'></v-switch>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-switch color="primary" v-model="filterId" value="hasID"
                                        label='Has Organisation LogLive ID(s)'></v-switch>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="showCBR === true">
                                <v-list-item-content>
                                    <v-switch color="primary" v-model="filterId" value="mismatchedIDs"
                                        label='Organisations with unequal LogLive ID(s) and CBR ID(s)'></v-switch>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="showCBR === true">
                                <v-list-item-content>
                                    <v-switch color="primary" v-model="filterId" value="matchedIDs"
                                        label='Organisations with equal LogLive ID(s) and CBR ID(s)'></v-switch>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogUnmap" persistent width="1200px" scrollable>
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Unmap these rules?</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon right @click="dialogUnmap = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-btn icon right @click="confirmMapRuleDeletion">
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card>
                    <v-data-table v-if="selectedMappedArr" :headers="mappedSelectedHeaders" :items="selectedMappedArr">
                    </v-data-table>
                </v-card>
            </v-card>
        </v-dialog>

        <v-dialog v-model="selectAllMappedDlg" persistent width="400px">
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Select All - Mapped</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon right @click="selectAllMappedDlg = false, selectAllMapped = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-btn icon right @click="commenceChosen('mapped', chosenSAVariant)" :disabled="!chosenSAVariant">
                            <v-icon>check</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    Choose the type of select all you want to commence:
                    <v-radio-group v-model="chosenSAVariant">
                        <v-radio value="page">
                            <template v-slot:label>
                                <div>This page's items</div>
                            </template>
                        </v-radio>
                        <v-radio value="all">
                            <template v-slot:label>
                                <div>All pages' items</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="selectAllUnmappedDlg" persistent width="400px">
            <v-card>
                <v-toolbar dark>
                    <v-toolbar-title class="white--text">Select All - Unmapped</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon right @click="selectAllUnmappedDlg = false, selectAllUnmapped = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-btn icon right @click="commenceChosen('unmapped', chosenSAVariant)
                            " :disabled="!chosenSAVariant">
                            <v-icon>check</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    Choose the type of select all you want to commence:
                    <v-radio-group v-model="chosenSAVariant">
                        <v-radio value="page">
                            <template v-slot:label>
                                <div>This page's items</div>
                            </template>
                        </v-radio>
                        <v-radio value="all">
                            <template v-slot:label>
                                <div>All pages' items</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbarVisible" :color="snackbarColor" :timeout="snackbarTimeout">{{ snackbarText
        }}</v-snackbar>
    </div>
</template>

  
<script>
import OrganisationMerging from "../views/OrganisationMerging.vue"
import OrganisationMappingLookup from "../components/SystemComponents/OrganisationMappingLookup.vue";
export default {
    // components: { OrganisationMerging },
    components: { OrganisationMappingLookup },
    data: () => ({
        chosenSAVariant: null,
        showCBRHeaders: [
            { text: 'Actions', value: 'actions' },
            { text: 'Name', value: 'name' },
            { text: 'LogLive ID(s)', value: 'ids', align: 'end' },
            { text: 'CBR ID(s)', value: 'cbrIds', align: 'end' },
        ],
        showAddressHeaders: [
            { text: 'Actions', value: 'actions' },
            { text: 'Name', value: 'name' },
            { text: 'Address', value: 'consigneeAddress' },
            { text: 'LogLive ID(s)', value: 'ids', align: 'end' },
        ],
        mappedHeaders: [
            { text: 'Actions', value: 'actions' },
            { text: 'ID', value: 'id' },
            { text: 'Field', value: 'field' },
            { text: 'Value', value: 'value' },
            { text: 'Mapped Field', value: 'mappedField' },
            { text: 'Mapped Value', value: 'mappedValue' },
            { text: 'Mapped Name', value: 'mappedValueName' },
        ],
        updateKey: 0,
        mrHeaders: [],
        mrData: null,
        selectedUnmappedArr: [],
        selectedMappedArr: [],
        showCBR: true,
        selectedItemName: null,
        selectAllUnmapped: false,
        selectAllMapped: false,
        selectedOrgs: [],
        mergeArray: [],     // Array to store checked checkboxes
        primaryOrg: null,   // Variable to store the value of the singular switch
        selectedMapped: null,
        selectedCheckbox: null,
        unmappedItems: [],
        unmappedHeaders: [],
        dialogMergement: false,
        dialogReactivateWarning: false,
        dialogSP: false,
        dialogDisable: false,
        disabledId: null,
        dialogUnmap: false,
        spItems: [],
        spHeaders: [],
        selectedItemId: null,
        selectedUnmapped: "",
        selectedRow: null, // Add selectedRow property
        selectedPartyOption: { name: "Billing Party", recordCount: "100" },
        unmappedOptionsLoading: false,
        mappedOptionsLoading: false,
        getOrganisationDetails: false,
        partyOptions: [
            { name: "Billing Party", recordCount: "100" },
            { name: "Exporter", recordCount: "200" },
            { name: "Consignee", recordCount: "50" },
        ],
        unmappedOptions: [],
        mappedOptions: [],
        unmappedSearch: null,
        mappedSearch: null,
        contactModal: false,
        contact: {},
        countries: [],
        countryKey: 0,
        headers: [
            {
                text: "Name",
                value: "relatedOrganisation.name",
                sortable: true,
                align: "left",
            },
            {
                text: "Country",
                value: "relatedOrganisation.countryRegion",
                sortable: false,
                align: "center",
            },
            {
                text: "Linked Profiles",
                value: "linkedProfiles",
                align: "center",
            },
            {
                text: "Customer",
                value: "isCustomer",
                sortable: true,
                align: "center",
            },

            {
                text: "Supplier",
                value: "isSupplier",
                sortable: true,
                align: "center",
            },
            {
                text: "Teams",
                value: "teams",
                sortable: false,
                align: "center",
            },
            {
                text: "Network Functions",
                sortable: false,
                filterable: true,
                value: "networkFunctions",
                align: "center",
            },
            {
                text: "Status",
                value: "isActive",
                sortable: true,
                align: "center",
            },
        ],
        loading: false,
        loadingUsers: false,
        isLoading: false,
        loadingTable: false,
        page: 1,
        params: {
            limit: 15,
            offset: 0,
            search: null,
            filter: { isActive: [true] },
            teamFilter: null,
            noTeams: false
        },
        networkFunctions: [
            {
                name: "Producer",
                attribute: "producer",
                selected: true,
            },
            {
                name: "Coldstore",
                attribute: "coldstore",
                selected: true,
            },
            {
                name: "Consignee",
                attribute: "consignee",
                selected: true,
            },
            {
                name: "Shipper",
                attribute: "shipper",
                selected: true,
            },
            {
                name: "Forwarder",
                attribute: "forwarder",
                selected: true,
            },
            {
                name: "Buyer",
                attribute: "buyer",
                selected: true,
            },
            {
                name: "Notify Party",
                attribute: "notifyParty",
                selected: true,
            },
        ],
        relationshipDialog: false,
        item: {},
        tags: [],
        tagKey: 0,
        organisations: {
            count: 0,
            data: [],
        },
        settings: {},
        tab: "0",
        tagSearch: "",
        timeout: null,
        filterDialog: false,
        filterActive: false,
        selectedFilterCountry: { name: '', iso: '' },
        loadingCountries: false,
        searchTimer: undefined,
        orgTeams: [],
        searchItem: {
            isActive: true,
            inActive: false,
            isCustomer: true,
            isSupplier: true,
            teams: [],
            country: ''
        },
        updateBadge: 2000,
        selectedOrganisationOne: null,
        selectedOrganisationTwo: null,
        snackbarVisible: false,
        snackbarColor: '',
        snackbarTimeout: 3000,
        snackbarText: '',
        mergeDependentLL: null,
        mergeDependencyLL: null,
        pageMapped: 1,
        paramsMapped: {
            limit: 10, // Initial page size
            offset: 0,
        },
        pageUnmapped: 1,
        paramsUnmapped: {
            limit: 10, // Initial page size
            offset: 0,
        },
        filterStatus: null,
        dialogFilter: false,
        orgIdArray: [],
        idFilterValue: 0,
        filterId: null,
        searchTimeout: null,
        dialogMap: false,
        selectedOption: null,
        mapRuleId: null,
        totalUnmapped: 0,
        totalMapped: 0,
        distinctBP: null,
        distinctConsignees: null,
        mappedRules: null,
    }),
    watch: {
        filterId: {
            handler: 'filterRefresh',
            immediate: true
        },
        unmappedSearch: {
            handler: function () {
                setTimeout(() => {
                    this.searchUnmapped();
                }, 3000);
            },
            immediate: true
        },
        mappedSearch: {
            handler: function () {
                setTimeout(() => {
                    this.searchMapped();
                }, 3000);
            },
            immediate: true
        },
        selectedRow(newRow, oldRow) {
            if (oldRow && !oldRow.selected) {
                this.selectedRow = null;
            }
        },
        pageMapped: {
            handler: async function () {
                this.selectAllMapped = false
                this.paramsMapped.offset = (this.pageMapped - 1) * this.paramsMapped.limit;
                if (this.selectedPartyOption.name === "Billing Party") {
                    this.getBillingPartyCBROrgsM();
                } else if (this.selectedPartyOption.name === "Exporter") {
                    this.getExporterCBROrgsM();
                } else if (this.selectedPartyOption.name === "Consignee") {
                    this.getConsigneeCBROrgsM();
                }
                await this.getRelations();
            },
            immediate: true
        },
        pageUnmapped: {
            handler: async function () {
                this.selectAllUnmapped = false
                this.paramsUnmapped.offset = (this.pageUnmapped - 1) * this.paramsUnmapped.limit;
                if (this.selectedPartyOption.name === "Billing Party") {
                    this.getBillingPartyCBROrgsUM();
                } else if (this.selectedPartyOption.name === "Exporter") {
                    this.getExporterCBROrgsUM();
                } else if (this.selectedPartyOption.name === "Consignee") {
                    this.getConsigneeCBROrgsUM();
                }
                await this.getRelations();
            },
            immediate: true
        },
        selectedFilterCountry(value) {
            if (value && value.name) {
                this.searchItem.country = value.name
            }
        },
        selectAllUnmapped(value) {
            console.log("Select All?: " + this.selectAllUnmapped)
            // const start = (this.pageUnmapped - 1) * this.paramsUnmapped.limit;
            // console.log(start)
            // const end = start + this.paramsUnmapped.limit;
            // console.log(end)
            console.log("unmapped options here ross")
            console.log(this.unmappedOptions)
            console.log("-----------------")
            if (value === false) {
                this.selectedUnmappedArr = []
                console.log(this.selectedUnmappedArr)
                this.filteredUnmappedOptions.forEach(option => {
                    option.selected = value
                })
            } else {
                this.unmappedOptions.forEach(option => {
                    option.selected = value;
                    this.saveToSelectedUnmapped(option)
                });
            }
            this.updateKey++
        },
        selectAllMapped(value) {
            console.log("Select All?: " + this.selectAllMapped)
            // const start = (this.pageMapped - 1) * this.paramsMapped.limit;
            // console.log(start)
            // const end = start + this.paramsMapped.limit;
            // console.log(end)
            let options = this.mappedOptions
            // .slice(start, end)
            console.log("mapped options here ross")
            console.log(options)
            if (value === false) {
                this.selectedMappedArr = []
                console.log(this.selectedMappedArr)
                this.filteredMappedOptions.forEach(option => {
                    option.selected = value
                })
            } else {
                options.forEach(option => {
                    option.selected = value;
                    this.saveToSelectedMapped(option)
                });
            }
            this.updateKey++
        }
    },
    computed: {
        searchedOrgHeaders() {
            if (this.selectedOrganisationOne) {
                const keys = Object.keys(this.selectedOrganisationOne);
                return keys.map(key => ({
                    text: key.charAt(0).toUpperCase() + key.slice(1),
                    value: key
                }));
            } else {
                return null;
            }
        },
        unmappedSelectedHeaders() {
            if (this.selectedUnmappedArr.length > 0) {
                const keys = Object.keys(this.selectedUnmappedArr[0]).filter(key => key !== 'selected');
                return keys.map(key => ({
                    text: key.charAt(0).toUpperCase() + key.slice(1),
                    value: key,
                }));
            } else {
                return [];
            }
        },
        mappedSelectedHeaders() {
            if (this.selectedMappedArr.length > 0) {
                const keys = Object.keys(this.selectedMappedArr[0]).filter(key => key !== 'selected');
                return keys.map(key => ({
                    text: key.charAt(0).toUpperCase() + key.slice(1),
                    value: key,
                }));
            } else {
                return [];
            }
        },
        showMappingButton() {
            return this.selectedPartyOption.name === 'Billing Party' || this.selectedPartyOption.name === 'Exporter';
        },
        isMergeDisabled() {
            return this.unmappedItems.filter(item => item.selected).length === 0;
        },
        darkMatterColumnDisabled() {
            return !this.selectedOrganisationOne;
        },
        formattedPartyOptions() {
            return this.partyOptions.map((option) => ({
                value: option,
                text: `${option.name}`,
            }));
        },
        formattedOrgOptions() {
            return this.orgOptions.map((option) => ({
                value: option,
                text: `${option.name} [${option.countryCode}]`,
            }));
        },
        filteredOrganisations() {
            let result = this.organisations.data
            let filter = {}
            // this.params.filter = {}
            if (this.searchItem.isActive && this.searchItem.inActive) { filter.isActive = [true, false] }
            else if (this.searchItem.isActive && !this.searchItem.inActive) { filter.isActive = [true] }
            else if (!this.searchItem.isActive && this.searchItem.inActive) { filter.isActive = [false] }
            filter.isSupplier = [this.searchItem.isSupplier]
            filter.isCustomer = [this.searchItem.isCustomer]
            if (this.params.noTeams) { filter.teamFilter = null }
            else { filter.teamFilter = this.searchItem.teams }
            filter = JSON.parse(JSON.stringify(filter))
            filter.teamFilter = JSON.parse(JSON.stringify(filter.teamFilter))

            if (filter.teamFilter && filter.teamFilter.length > 0) {
                result = result.filter(x => x.relationshipTeams && x.relationshipTeams.some(y => filter.teamFilter.includes(y.teamId)))
            }
            if (filter.isActive && filter.isActive.length > 0) {
                result = result.filter(x => filter.isActive.includes(x.isActive))
            }
            if (filter.isSupplier.length > 0 || filter.isCustomer.length > 0) {
                result = result.filter(x => filter.isCustomer.includes(x.isCustomer) || filter.isSupplier.includes(x.isSupplier))
            }
            return result
        },
        filteredUnmappedOptions() {
            // const start = (this.pageUnmapped - 1) * this.paramsUnmapped.limit;
            // const end = start + this.paramsUnmapped.limit;
            let options = this.unmappedOptions

            options = options.sort((a, b) => a.name.localeCompare(b.name));

            console.log("filteredUnmappedOptions")
            console.log(options)
            console.log("-----------")

            return options;
        },
        filteredMappedOptions() {
            let allOptions = this.mappedOptions

            // const start = (this.pageMapped - 1) * this.paramsMapped.limit;
            // const end = start + this.paramsMapped.limit;

            let options = (allOptions.sort((a, b) => a.id - b.id))
            // .slice(start, end);

            return options;
        },
    },
    methods: {
        async dialogSAUnmapped(option) {
            if (option === true) {
                this.selectAllUnmappedDlg = true
            }
        },
        async dialogSAMapped(option) {
            if (option === true) {
                this.selectAllMappedDlg = true
            }
        },
        async commenceChosen(section, chosenSAVariant) {
            console.log('commenceChosen method executed');
            console.log(chosenSAVariant);
            if (chosenSAVariant === "page") {
                if (section === "unmapped") {
                    this.selectPageUnmappedOptions()

                    this.chosenSAVariant = null
                    this.selectAllUnmappedDlg = false
                } else if (section === "mapped") {
                    this.selectPageMappedOptions()

                    this.chosenSAVariant = null
                    this.selectAllMappedDlg = false
                }
            } else if (chosenSAVariant === "all") {
                if (section === "unmapped") {
                    console.log("Fetching Items Related to " + this.selectedPartyOption.name)
                    const partyName = this.selectedPartyOption.name
                    if (partyName === "Billing Party" || partyName === "Exporter") {
                        this.unmappedOptionsLoading = true;
                        let params = {}
                        params.noLimit = true
                        const cbrResponse = await this.$API.cbrOrgCheckBPEX({ distinctBP: this.distinctBP, mappedRules: this.mappedRules }, params);
                        console.log({ unmappedRecords: cbrResponse.unmappedRecords })
                        this.selectedUnmappedArr = cbrResponse.unmappedRecords
                        this.unmappedOptionsLoading = false;
                    } else if (partyName === "Consignee") {
                        this.unmappedOptionsLoading = true;
                        let params = {}
                        params.noLimit = true
                        const cbrResponse = await this.$API.cbrOrgCheckCS({ distinctConsignees: this.distinctConsignees, mappedRules: this.mappedRules }, params)
                        // console.log({ unmappedRecords: cbrResponse.unmappedRecords })
                        this.selectedUnmappedArr = cbrResponse.unmappedRecords
                        this.unmappedOptionsLoading = false;
                    }

                    this.chosenSAVariant = null
                    this.selectAllUnmappedDlg = false
                } else if (section === "mapped") {
                    console.log(`Fetching Items Related For Mapped`)
                    this.mappedOptionsLoading = true;
                    let params = {}
                    params.notLimit = true;
                    params.limit = null
                    if (this.mappedSearch) {
                        console.log("Mapped Search: " + this.mappedSearch)
                        params.value = this.mappedSearch
                    }
                    const mrList = await this.$API.getMRList({ party: this.selectedPartyOption.name }, params);
                    console.log({ fetchedMappedRules: mrList.mappedRules })
                    this.selectedMappedArr = mrList.mappedRules
                    this.mappedOptionsLoading = false;
                    this.chosenSAVariant = null
                    this.selectAllMappedDlg = false
                }
            }
        },
        async searchUnmapped() {
            if (this.selectedPartyOption.name === "Billing Party") {
                console.log(this.selectedPartyOption.name)
                this.getBillingPartyCBROrgsUM();
                return;
            } else if (this.selectedPartyOption.name === "Exporter") {
                console.log(this.selectedPartyOption.name)
                this.getExporterCBROrgsUM();
                return;
            } else if (this.selectedPartyOption.name === "Consignee") {
                console.log(this.selectedPartyOption.name)
                this.getConsigneeCBROrgsUM();
                return;
            }
        },
        async searchMapped() {
            if (this.selectedPartyOption.name === "Billing Party") {
                console.log(this.selectedPartyOption.name)
                this.getBillingPartyCBROrgsM();
                return;
            } else if (this.selectedPartyOption.name === "Exporter") {
                console.log(this.selectedPartyOption.name)
                this.getExporterCBROrgsM();
                return;
            } else if (this.selectedPartyOption.name === "Consignee") {
                console.log(this.selectedPartyOption.name)
                this.getConsigneeCBROrgsM();
                return;
            }
        },
        async deleteRule(item) {
            console.log("Row clicked:", item);
            const id = item.id
            this.mapRuleId = id;
        },
        async confirmMapRuleDeletion() {
            try {
                this.mappedOptionsLoading = true
                this.dialogUnmap = false
                for (let i = 0; i < this.selectedMappedArr.length; i++) {
                    let id = this.selectedMappedArr[i].id
                    const response = await this.$API.deleteMapRule({ id: id, user: this.$store.state.user.id })
                }
                this.showSnackbar("Unmapped!", "success")
                this.handlePartyOptionChange(this.selectedPartyOption)
            } catch (error) {
                this.showSnackbar("Unmapping Failed Failed!: " + error, "error")
            }
        },
        getAddressString(addresses) {
            return addresses.map(address => {
                const parts = [
                    address.addressLine1,
                    address.addressLine2,
                    address.addressLine3,
                    address.addressLine4,
                    address.postalCode
                ].filter(Boolean); // Remove null or undefined values

                return parts.join(', ');
            }).join('; ');
        },
        async resumeMapping() {
            if (this.selectedUnmappedArr && this.selectedOrganisationOne) {
                this.dialogMap = true
            } else {
                this.snackbarText = "No mapping to resume";
                this.snackbarColor = "red";
                this.snackbarVisible = true;
            }
        },
        async commenceMapping() {
            const userId = this.$store.state.user.id;
            const table = "eCertCertificates"
            const field = this.selectedPartyOption.name;
            const mappedTable = 'Organisations'
            const selectedUnmaps = this.selectedUnmappedArr.map((option) => ({
                cbrIds: option.cbrIds,
                ids: option.ids,
                name: option.name
            }))
            let mappedField;
            if (field === "Billing Party") {
                mappedField = "billingPartyId"
            } else if (field === "Exporter") {
                mappedField = "exporterId"
            } else if (field === "Consignee") {
                mappedField = "consigneeId"
            }
            const mappingObj = {
                userId: userId,
                table: table,
                field: field,
                mappedTable: mappedTable,
                mappedField: mappedField,
                selectedOrgs: selectedUnmaps,
                searchedOrg: this.selectedOrganisationOne
            };

            // console.log(mappingObj);
            const response = await this.$API.commenceMapping(mappingObj);
            // console.log(response)
            this.unmappedOptions = this.unmappedOptions.filter(option => !this.selectedUnmappedArr.includes(option));

            this.showSnackbar("Mapping Rule(s) Set", "success");
        },
        async autoMapper(mappedOrgs) {
            // console.log({ mappedOrgs: mappedOrgs, count: mappedOrgs.length })
            for (let i = 0; i < mappedOrgs.length; i++) {
                const userId = this.$store.state.user.id;
                const table = "eCertCertificates"
                const field = this.selectedPartyOption.name;
                const mappedTable = 'Organisations'
                let mappedField;
                if (field === "Billing Party") {
                    mappedField = "billingPartyId"
                } else if (field === "Exporter") {
                    mappedField = "exporterId"
                } else if (field === "Consignee") {
                    mappedField = "consigneeId"
                }
                const mappingObj = {
                    userId: userId,
                    table: table,
                    field: field,
                    mappedTable: mappedTable,
                    mappedField: mappedField,
                    selectedOrgs: [mappedOrgs[i]],
                    searchedOrg: mappedOrgs[i]
                };

                const response = await this.$API.commenceMapping(mappingObj);
            }

        },
        selectPageUnmappedOptions() {
            console.log(this.selectAllUnmapped)
            if (!this.selectAllUnmapped === false) {
                this.selectAllUnmapped = true
            } else {
                this.selectAllUnmapped = false
            }
        },
        selectPageMappedOptions() {
            console.log(this.selectAllMapped)
            if (!this.selectAllMapped === false) {
                this.selectAllMapped = true
            } else {
                this.selectAllMapped = false
            }
        },
        async updateUnmappedList(option) {
            const index = this.unmappedOptions.findIndex(item => item.name === option.name);
            if (index !== -1) {
                const removedItem = this.unmappedOptions.splice(index, 1)[0];
                this.mappedOptions.push(removedItem);
            }
        },
        handleCheckboxChange(item) {
            if (this.selectedCheckbox === item) {
                // If the same checkbox is clicked again, unselect it and enable all checkboxes
                this.selectedCheckbox = null;
                this.unmappedItems.forEach((i) => {
                    i.disabled = false;
                });
            } else {
                // Unselect the previously selected checkbox and enable all checkboxes
                if (this.selectedCheckbox) {
                    this.selectedCheckbox.selected = false;
                    this.selectedCheckbox.disabled = false;
                }

                // Select the clicked checkbox and disable all other checkboxes
                item.selected = true;
                item.disabled = true;
                this.selectedCheckbox = item;
            }
        },
        async commenceUnmapping() {
            // console.log(this.selectedUnmappedArr)
            // this.dialogUnmap = true
            const response = await this.$API.displayMapRules();
            // console.log(response)
            this.mrHeaders = [
                ...Object.keys(response[0]).map((key) => ({
                    text: key.charAt(0).toUpperCase() + key.slice(1),
                    value: key,
                })),
            ];
            this.mrData = response
            console.log(this.mrHeaders)
            console.log(this.mrData)
        },
        async handleRowClick(row) {
            if (row.selected) {
                this.selectedRow = row.id; // Assuming the ID property of the row object is called "id"
                // Rest of the method code
                console.log(this.selectedRow);
            }
        },
        async removeItem() {
            if (this.selectedRow) {
                this.unmappedItems = this.unmappedItems.filter(item => item.id !== this.selectedRow);
            }
        },
        async openDialog(item) {
            this.dialogSP = true;
            this.selectedItemName = item.name
            console.log(this.selectedItemName)
            this.selectedItemId = item.id;
            console.log(this.selectedItemId)
            const response = await this.$API.countConsigneeProfiles(this.selectedItemId);
            const records = response.records
            const firstObject = records[0]
            const headers = [
                ...Object.keys(firstObject).map((key) => ({
                    text: key.charAt(0).toUpperCase() + key.slice(1),
                    value: key,
                })),
            ];

            const spItems = records.map((result) => {
                const item = {};
                headers.forEach((header) => {
                    item[header.value] = result[header.value];
                });
                return item;
            });

            this.spHeaders = headers;
            this.spItems = spItems;
            console.log(headers);
            console.log(spItems)
        },
        async saveToSelectedUnmapped(option) {
            if (option.selected) {
                this.selectedUnmappedArr.push(option);
                console.log(this.selectedUnmappedArr);

            } else {
                const existingIndex = this.selectedUnmappedArr.findIndex(item => item === option);
                if (existingIndex !== -1) {
                    this.selectedUnmappedArr.splice(existingIndex, 1); // Remove the item from the array
                    console.log(this.selectedUnmappedArr);
                }
            }
        },
        async saveToSelectedMapped(option) {
            if (option.selected) {
                this.selectedMappedArr.push(option);
                console.log(this.selectedMappedArr);

            } else {
                const existingIndex = this.selectedMappedArr.findIndex(item => item === option);
                if (existingIndex !== -1) {
                    this.selectedMappedArr.splice(existingIndex, 1); // Remove the item from the array
                    console.log(this.selectedMappedArr);
                }
            }
        },
        handlePartyOptionChange(selectedOption) {
            this.selectedUnmappedArr = []
            this.selectedMappedArr = []
            console.log(this.selectedPartyOption)
            if (selectedOption && selectedOption.name === "Billing Party") {
                this.getBillingPartyCBROrgsUM();
                this.getBillingPartyCBROrgsM()
                this.showCBR = true;
            } else if (selectedOption && selectedOption.name === "Exporter") {
                this.getExporterCBROrgsUM();
                this.getExporterCBROrgsM();
                this.showCBR = true;
            } else {
                this.getConsigneeCBROrgsUM();
                this.getConsigneeCBROrgsM();
                this.showCBR = false;
            }
        },
        async removeDuplicateObjects(arr) {
            const uniqueObjects = [];
            const uniqueKeys = new Set();

            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const objKey = JSON.stringify(obj);

                if (!uniqueKeys.has(objKey)) {
                    uniqueObjects.push(obj);
                    uniqueKeys.add(objKey);
                }
                // console.log(i)
            }

            console.log("Finsihed Filtering")
            return uniqueObjects;
        },
        refreshLists() {
            this.$refs.mappedList.forceUpdate();
            this.$refs.unmappedList.forceUpdate();
        },
        async getBillingPartyCBROrgsUM() {
            try {
                this.unmappedOptionsLoading = true;
                const { limit, offset } = this.paramsUnmapped;
                const unmappedSearch = this.unmappedSearch
                const filterId = this.filterId
                const params = {};
                const oldParams = {};
                const comparisonParams = {};

                if (unmappedSearch) {
                    console.log(unmappedSearch);
                    oldParams.name = unmappedSearch;
                }
                if (offset) {
                    params.offset = offset;
                }
                if (limit) {
                    comparisonParams.limit = null;
                    params.limit = limit;
                }
                if (filterId) {
                    console.log(filterId);
                    params.filterId = filterId;
                }

                console.log({ params });

                const { distinctBillingParties } = await this.$API.getDistinctBillingParties(oldParams);
                this.distinctBP = distinctBillingParties
                console.log({ distinctBP: distinctBillingParties, totalCount: this.totalUnmapped });

                const { mappedRules } = await this.$API.getMRList({ party: this.selectedPartyOption.name }, comparisonParams);
                this.mappedRules = mappedRules
                const cbrResponse = await this.$API.cbrOrgCheckBPEX({ distinctBP: distinctBillingParties, mappedRules }, params);

                this.totalUnmapped = cbrResponse.totalCount;
                const { mappedRecords, unmappedRecords } = cbrResponse;

                console.log({ mappedOptionsNEWVERSION: mappedRecords, unmappedOptionsNEWVERSION: unmappedRecords });

                this.unmappedOptions = unmappedRecords;
                this.unmappedOptionsLoading = false;
            } catch (error) {
                console.error("An error occurred while fetching distinct exporters: ", error);
                throw error;
            }
        },
        async getBillingPartyCBROrgsM() {
            try {
                this.mappedOptionsLoading = true;
                const { limit, offset } = this.paramsMapped;
                let params = {};
                let oldParams = {};
                let comparisonParams = {};

                if (this.mappedSearch) {
                    console.log("MAPPED SEARCH ITEM: " + this.mappedSearch);
                    oldParams.value = this.mappedSearch;
                }

                if (offset) {
                    console.log("Offset: " + offset);
                    params.offset = oldParams.offset = offset;
                }

                if (limit) {
                    console.log("Limit: " + limit);
                    params.limit = oldParams.limit = limit;
                    comparisonParams.limit = null;
                }

                console.log({ params });

                console.log("getMRList" + "->>>" + this.selectedPartyOption.name);
                const mrListresponse = await this.$API.getMRList({ party: this.selectedPartyOption.name }, oldParams);
                this.mappedOptions = mrListresponse.mappedRules;
                this.totalMapped = mrListresponse.totalCount;
                this.mappedOptionsLoading = false;

                const { distinctBillingParties } = await this.$API.getDistinctBillingParties(oldParams);
                console.log({ distinctBP: distinctBillingParties, totalCount: this.totalUnmapped });

                const mrList = await this.$API.getMRList({ party: this.selectedPartyOption.name }, comparisonParams);
                const cbrResponse = await this.$API.cbrOrgCheckBPEX({
                    distinctBP: distinctBillingParties,
                    mappedRules: mrList.mappedRules
                }, params);

                let mappedOptions = cbrResponse.mappedRecords;

                const withIdResponseOne = await this.$API.getDistinctBillingPartiesWithId(params);
                const withIdResponseTwo = await this.$API.cbrOrgCheckBPEX({
                    distinctBP: withIdResponseOne,
                    mappedRules: mrList.mappedRules
                });
                const additionalMappedOptions = withIdResponseTwo.mappedRecords;

                mappedOptions = await this.removeDuplicateObjects(mappedOptions.concat(additionalMappedOptions));
                // this.autoMapper(mappedOptions);
            } catch (error) {
                console.error("An error occurred while fetching distinct billing parties: ", error);
                throw error;
            }
        },
        async getExporterCBROrgsUM() {
            try {
                this.unmappedOptionsLoading = true;
                const { limit, offset } = this.paramsUnmapped;
                const unmappedSearch = this.unmappedSearch
                const filterId = this.filterId
                const params = {};
                const oldParams = {};
                const comparisonParams = {};

                if (unmappedSearch) {
                    console.log(unmappedSearch);
                    oldParams.name = unmappedSearch;
                }
                if (offset) {
                    params.offset = offset;
                }
                if (limit) {
                    comparisonParams.limit = null;
                    params.limit = limit;
                }
                if (filterId) {
                    console.log(filterId);
                    params.filterId = filterId;
                }

                console.log({ params });

                const { distinctExporters } = await this.$API.getDistinctExporters(oldParams);
                console.log({ distinctBP: distinctExporters, totalCount: this.totalUnmapped });

                const { mappedRules } = await this.$API.getMRList({ party: this.selectedPartyOption.name }, comparisonParams);
                const cbrResponse = await this.$API.cbrOrgCheckBPEX({ distinctBP: distinctExporters, mappedRules }, params);

                this.totalUnmapped = cbrResponse.totalCount;
                const { mappedRecords, unmappedRecords } = cbrResponse;

                console.log({ mappedOptionsNEWVERSION: mappedRecords, unmappedOptionsNEWVERSION: unmappedRecords });

                this.unmappedOptions = unmappedRecords;
                this.unmappedOptionsLoading = false;
            } catch (error) {
                console.error("An error occurred while fetching distinct exporters: ", error);
                throw error;
            }
        },
        async getExporterCBROrgsM() {
            try {
                this.mappedOptionsLoading = true;
                const { limit, offset } = this.paramsMapped;
                let params = {};
                let oldParams = {};
                let comparisonParams = {};

                if (this.mappedSearch) {
                    console.log("MAPPED SEARCH ITEM: " + this.mappedSearch);
                    oldParams.value = this.mappedSearch;
                }

                if (offset) {
                    console.log("Offset: " + offset);
                    params.offset = oldParams.offset = offset;
                }

                if (limit) {
                    console.log("Limit: " + limit);
                    params.limit = oldParams.limit = limit;
                    comparisonParams.limit = null;
                }

                console.log({ params });

                console.log("getMRList" + "->>>" + this.selectedPartyOption.name);
                const mrListresponse = await this.$API.getMRList({ party: this.selectedPartyOption.name }, oldParams);
                this.mappedOptions = mrListresponse.mappedRules;
                this.totalMapped = mrListresponse.totalCount;
                this.mappedOptionsLoading = false;

                const { distinctExporters } = await this.$API.getDistinctExporters(oldParams);
                console.log({ distinctBP: distinctExporters, totalCount: this.totalUnmapped });

                const mrList = await this.$API.getMRList({ party: this.selectedPartyOption.name }, comparisonParams);
                const cbrResponse = await this.$API.cbrOrgCheckBPEX({
                    distinctBP: distinctExporters,
                    mappedRules: mrList.mappedRules
                }, params);

                let mappedOptions = cbrResponse.mappedRecords;

                const withIdResponseOne = await this.$API.getDistinctExportersWithId(params);
                const withIdResponseTwo = await this.$API.cbrOrgCheckBPEX({
                    distinctBP: withIdResponseOne,
                    mappedRules: mrList.mappedRules
                });
                const additionalMappedOptions = withIdResponseTwo.mappedRecords;

                mappedOptions = await this.removeDuplicateObjects(mappedOptions.concat(additionalMappedOptions));
                // this.autoMapper(mappedOptions);
            } catch (error) {
                console.error("An error occurred while fetching distinct exporters: ", error);
                throw error;
            }
        },
        async getConsigneeCBROrgsUM() {
            try {
                this.unmappedOptionsLoading = true;
                const { limit, offset } = this.paramsUnmapped;
                const unmappedSearch = this.unmappedSearch
                const filterId = this.filterId
                const params = {};
                const oldParams = {};
                const comparisonParams = {};

                if (unmappedSearch) {
                    console.log(unmappedSearch);
                    oldParams.name = unmappedSearch;
                }
                if (offset) {
                    params.offset = offset;
                }
                if (limit) {
                    comparisonParams.limit = null;
                    params.limit = limit;
                }
                if (filterId) {
                    console.log(filterId);
                    params.filterId = filterId;
                }

                console.log({ consigneeParams: params });
                const { distinctConsignees, totalCount } = await this.$API.getDistinctConsignees(oldParams);
                this.distinctConsignees = distinctConsignees
                console.log({ distinctConsignees: distinctConsignees, totalUnmapped: totalCount })

                const { mappedRules } = await this.$API.getMRList({ party: this.selectedPartyOption.name }, comparisonParams);
                const consigneeResponse = await this.$API.cbrOrgCheckCS({
                    distinctConsignees: distinctConsignees,
                    mappedRules: mappedRules
                }, params)

                this.totalUnmapped = consigneeResponse.totalCount;
                const { mappedRecords, unmappedRecords } = consigneeResponse;

                console.log({ mappedOptionsNEWVERSION: mappedRecords, unmappedOptionsNEWVERSION: unmappedRecords });

                this.unmappedOptions = unmappedRecords;
                this.unmappedOptionsLoading = false;

                // this.unmappedOptions = []
                // const unmappedOptions = [];
                // let mappedOptions = [];

                // const foundRecords = consigneeResponse.updatedRecords;
                // const notFoundRecords = await this.removeDuplicateObjects(consigneeResponse.notFoundRecords);
                // console.log({ notFoundRecords: notFoundRecords })

                // // Iterate over each object in the array
                // for (let i = 0; i < foundRecords.length; i++) {
                //     const obj = foundRecords[i];

                //     // Convert ids to a string list format
                //     if (obj.ids && Array.isArray(obj.ids)) {
                //         obj.ids = obj.ids.join(', '); // Assuming comma-separated values
                //     }

                //     // Determine whether the object has multiple cbrIds or ids
                //     const hasMultipleIds = obj.ids && obj.ids.includes(',');

                //     // Add the object to the appropriate array
                //     if (hasMultipleIds) {
                //         unmappedOptions.push(obj);
                //     } else {
                //         mappedOptions.push(obj);
                //     }
                // }

                // const withIdResponseOne = await this.$API.getDistinctConsigneesWithId(oldParams)
                // const withIdResponseTwo = await this.$API.cbrOrgCheckCS({
                //     distinctConsignees: withIdResponseOne
                // });
                // const additionalMappedOptions = withIdResponseTwo.updatedRecords.map(option => {
                //     option.ids = option.ids.join(',');
                //     return option;
                // });

                // this.unmappedOptions = await this.removeDuplicateObjects(unmappedOptions);
                // // Merge notFoundObjects with unmappedOptions
                // if (this.filterId !== "hasID") {
                //     this.unmappedOptions.push(...notFoundRecords);
                //     const unfilteredUmapped = this.unmappedOptions;
                //     this.unmappedOptions = await this.removeDuplicateObjects(unfilteredUmapped)
                // }

                // // console.log(unfilteredUmapped)
                // // console.log("-------")
                // if (this.filterId === "noID") {
                //     this.unmappedOptions = notFoundRecords
                // }

                // mappedOptions = await this.removeDuplicateObjects(mappedOptions.concat(additionalMappedOptions));
                // this.unmappedOptions = this.unmappedOptions.filter(option => {
                //     return !mappedOptions.mappedRules.some(mappedOption => mappedOption.value === option.name);
                // });

            } catch (error) {
                console.error("An error occurred while fetching distinct consignees: ", error);
                throw error;
            }
        },
        async getConsigneeCBROrgsM() {
            try {
                this.mappedOptionsLoading = true;
                const { limit, offset } = this.paramsMapped;
                let params = {};
                let oldParams = {};
                let comparisonParams = {};

                if (this.mappedSearch) {
                    console.log("MAPPED SEARCH ITEM: " + this.mappedSearch);
                    oldParams.value = this.mappedSearch;
                }

                if (offset) {
                    console.log("Offset: " + offset);
                    params.offset = oldParams.offset = offset;
                }

                if (limit) {
                    console.log("Limit: " + limit);
                    params.limit = oldParams.limit = limit;
                    comparisonParams.limit = null;
                }

                console.log({ params });

                console.log("getMRList" + "->>>" + this.selectedPartyOption.name);
                const mrListresponse = await this.$API.getMRList({ party: this.selectedPartyOption.name }, oldParams);
                this.mappedOptions = mrListresponse.mappedRules;
                this.totalMapped = mrListresponse.totalCount;
                this.mappedOptionsLoading = false;

                const { distinctConsignees } = await this.$API.getDistinctConsignees(oldParams);
                console.log({ distinctConsignees: distinctConsignees, totalCount: this.totalUnmapped });

                const mrList = await this.$API.getMRList({ party: this.selectedPartyOption.name }, comparisonParams);
                const cbrResponse = await this.$API.cbrOrgCheckCS({
                    distinctConsignees: distinctConsignees,
                    mappedRules: mrList.mappedRules
                }, params);

                let mappedOptions = cbrResponse.mappedRecords;

                const withIdResponseOne = await this.$API.getDistinctConsigneesWithId(params);
                const withIdResponseTwo = await this.$API.cbrOrgCheckCS({
                    distinctBP: withIdResponseOne,
                    mappedRules: mrList.mappedRules
                });
                const additionalMappedOptions = withIdResponseTwo.mappedRecords;

                mappedOptions = await this.removeDuplicateObjects(mappedOptions.concat(additionalMappedOptions));
                // this.autoMapper(mappedOptions);

                // const limit = this.paramsMapped.limit;
                // const offset = this.paramsMapped.offset
                // let params = {};
                // let oldParams = {}
                // if (this.unmappedSearch) {
                //     console.log(this.unmappedSearch)
                //     oldParams.name = this.unmappedSearch;
                // }
                // if (offset) {
                //     console.log(offset)
                //     params.offset = offset
                // }
                // if (limit) {
                //     console.log(limit)
                //     params.limit = limit
                // }
                // this.mappedOptionsLoading = true;
                // const distinctConsignees = await this.$API.getDistinctConsignees(oldParams);
                // // const distinctBP = distinctConsignees.map((item) => item.consignee);
                // // const distinctAddresses = distinctConsignees.map((item) => item.consigneeAddress);
                // // console.log(distinctBP)
                // // console.log(distinctAddresses)
                // const consigneeResponse = await this.$API.cbrOrgCheckCS({
                //     distinctConsignees: distinctConsignees
                // }, params)
                // console.log(consigneeResponse)

                // this.mappedOptions = []
                // const unmappedOptions = [];
                // let mappedOptions = [];

                // const foundRecords = consigneeResponse.updatedRecords;
                // const notFoundRecords = await this.removeDuplicateObjects(consigneeResponse.notFoundRecords);

                // // Iterate over each object in the array
                // for (let i = 0; i < foundRecords.length; i++) {
                //     const obj = foundRecords[i];

                //     // Convert ids to a string list format
                //     if (obj.ids && Array.isArray(obj.ids)) {
                //         obj.ids = obj.ids.join(', '); // Assuming comma-separated values
                //     }

                //     // Determine whether the object has multiple cbrIds or ids
                //     const hasMultipleIds = obj.ids && obj.ids.includes(',');

                //     // Add the object to the appropriate array
                //     if (hasMultipleIds) {
                //         unmappedOptions.push(obj);
                //     } else {
                //         mappedOptions.push(obj);
                //     }
                // }

                // // this.mappedOptions = mappedOptions;
                // const withIdResponseOne = await this.$API.getDistinctConsigneesWithId(oldParams)
                // const withIdResponseTwo = await this.$API.cbrOrgCheckCS({
                //     distinctConsignees: withIdResponseOne
                // });
                // const additionalMappedOptions = withIdResponseTwo.updatedRecords.map(option => {
                //     option.ids = option.ids.join(',');
                //     return option;
                // });

                // // console.log(additionalMappedOptions);

                // mappedOptions = await this.removeDuplicateObjects(mappedOptions.concat(additionalMappedOptions));
                // console.log({ potentialTroubleMaker: mappedOptions })
                // this.autoMapper(mappedOptions)
                // console.log("getMRList" + "->>>" + this.selectedPartyOption.name)
                // const mappedBP = await this.$API.getMRList({ party: this.selectedPartyOption.name }, params)
                // this.mappedOptions = mappedBP
                // this.mappedOptionsLoading = false;

            } catch (error) {
                console.error("An error occurred while fetching distinct consignees: ", error);
                throw error;
            }
        },
        showSnackbar(text, type) {
            this.snackbarText = text;
            this.snackbarColor = type === 'success' ? 'success' : 'error';
            this.snackbarVisible = true;
        },
        async handleSelection(row) {
            this.selectedOrganisationOne = row
            this.getOrganisationDetails = false
            console.log(this.selectedOrganisationOne)
            this.dialogMap = true
        },
        toggleChecked(option) {
            option.checked = !option.checked;
        },
        async getTeams() {
            let teams = await this.$API.getTeams(this.orgId)
            this.orgTeams = teams
        },
        async getUserTeams() {
            this.myTeams = await this.$API.getUserTeams()
            this.searchItem.teams = this.myTeams.map(x => x.teamId)
            // this.filterSearch()
        },
        async getCountries() {
            this.loadingCountries = true
            let result = await this.$API.searchCountries();
            for (let i = 0; i < result.data.length; i++) {
                this.countries.push({
                    name: result.data[i].name,
                    iso2: result.data[i].iso2
                })
            }
            this.loadingCountries = false
        },
        async getRelations() {
            if (this.searchTimer) { clearTimeout(this.searchTimer) }
            this.loadingTable = true;
            this.searchTimer = setTimeout(async () => {
                this.organisations = await this.$API.getRelation({
                    // params: this.params,
                });
                this.loadingTable = false;
            }, 500)
        },
        async handleSelect(item) {
            if (this.item.id) {
                let result = await this.$API.createOrganisationTag({
                    tagId: item.id,
                    organisationId: this.item.id,
                });
                this.item.organisationTags.push(result);
            } else {
                this.item.organisationTags.push({
                    tag: item,
                    tagId: item.id,
                    organisationId: this.item.id,
                });
            }
        },
        async getOrganisationNetworkFunctions() {
            this.settings = await this.$API.getOrganisationNetworkFunctions();
        },
        clear() {
            this.loading = false
            this.relationshipDialog = false
            this.item = {
                relatedOrganisation: {},
            };
        },
        async filterSearch() {
            this.params.filter = {}
            if (this.searchItem.isActive && this.searchItem.inActive) { this.params.filter.isActive = [true, false] }
            else if (this.searchItem.isActive && !this.searchItem.inActive) { this.params.filter.isActive = [true] }
            else if (!this.searchItem.isActive && this.searchItem.inActive) { this.params.filter.isActive = [false] }
            this.params.filter.isSupplier = [this.searchItem.isSupplier]
            this.params.filter.isCustomer = [this.searchItem.isCustomer]
            if (this.params.noTeams) { this.params.teamFilter = null }
            else { this.params.teamFilter = this.searchItem.teams }
            this.params.filter = JSON.parse(JSON.stringify(this.params.filter))
            this.params.teamFilter = JSON.parse(JSON.stringify(this.params.teamFilter))
            this.filterActive = true
            this.filterDialog = false
            this.updateBadge++
            // this.getRelations()
        },
        closeFilterDialog() {
            if (!this.filterActive) { this.clearFilterDialog() }
            this.filterDialog = false
        },
        clearFilters() {
            this.clearFilterDialog()
            // this.getRelations()
        },
        async clearFilterDialog() {
            this.params.filter = { isActive: [true] }
            this.params.teamFilter = null
            this.params.noTeams = false
            this.filterDialog = false
            this.filterActive = false
            this.selectedFilterCountry = undefined
            this.page = 1
            this.searchItem = {
                country: null,
                isActive: true,
                inActive: false,
                isCustomer: true,
                isSupplier: false,
                teams: null,
            };
        },
        async filterRefresh() {
            if (this.selectedPartyOption && this.selectedPartyOption.name === "Billing Party") {
                await this.getBillingPartyCBROrgsUM();
                this.showCBR = true;
            } else if (this.selectedPartyOption && this.selectedPartyOption.name === "Exporter") {
                await this.getExporterCBROrgsUM();
                this.showCBR = true;
            } else if (this.selectedPartyOption && this.selectedPartyOption.name === "Consignee") {
                await this.getConsigneeCBROrgsUM();
                this.showCBR = false;
            }
        },
        async refreshList() {
            this.$refs.unmappedList.forceUpdate();
            this.$refs.mappedList.forceUpdate();
        }
    },
    async created() {
        this.getRelations();
        this.getUserTeams()
        this.getOrganisationNetworkFunctions();
        this.getCountries()
        this.getTeams()
    },
    mounted() {
        if (this.formattedPartyOptions.length > 0) {
            this.selectedPartyOption = this.formattedPartyOptions[0].value;
        }
    },
};
</script>

<style scoped>
.no-outline:focus {
    outline: none;
}
</style>